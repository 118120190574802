import React, {useEffect} from 'react';
import ProgressiveImageLoader from '../../common/ProgressiveImageLoader/ProgressiveImageLoader';

import {gsap} from 'gsap';

import image from '../../../resources/images/personal-photo.png';
import image_small from '../../../resources/images/personal-photo-thumb.png';

import './Main.scss'

const Main = () => {
  const one = React.createRef<HTMLDivElement>();
  const two = React.createRef<HTMLDivElement>();
  const three = React.createRef<HTMLDivElement>();
  const four = React.createRef<HTMLDivElement>();
  useEffect(() => {
    gsap.fromTo(one.current, {
      y: '7vh',
      opacity: 0,
      ease: "power2.out",
      duration: .5,
      delay: 1
    }, {
      y: '0',
      opacity: 1,
      ease: "power2.out",
      duration: .5,
      delay: 1
    })
    gsap.fromTo(two.current, {
      y: '7vh',
      opacity: 0,
      ease: "power2.out",
      duration: .5,
      delay: 1
    }, {
      y: '0',
      opacity: 1,
      ease: "power2.out",
      duration: .5,
      delay: 1.1
    });
    gsap.fromTo(three.current, {
      y: '7vh',
      opacity: 0,
      ease: "power2.out",
      duration: .5,
      delay: 1
    }, {
      y: '0',
      opacity: 1,
      ease: "power2.out",
      duration: .5,
      delay: 1.2
    });
    gsap.fromTo(four.current, {
      y: '7vh',
      opacity: 0,
      ease: "power2.out",
      duration: .5,
      delay: 1
    }, {
      y: '0',
      opacity: 1,
      ease: "power2.out",
      duration: .5,
      delay: 1.3
    });
  }, [one, two, three, four])
  return (
    <div className="main full-screen">
      <div className="main__message">
        <div className='text-4xl sm:text-6xl font-bold w-full flex flex-row justify-center'>
          <div className='m-1 main__item' ref={one}>Hi,</div>
          <div className='m-1 main__item' ref={two}>I'm</div>
          <div className='m-1 main__item' ref={three}>Valentin</div>
          <div className='m-1 main__item' ref={four}>!</div>
        </div>
      </div>
      <div className="full-screen main__background">
        <ProgressiveImageLoader src={image} thumb={image_small} alt={''}/>
      </div>

    </div>
  );
};

export default Main;
