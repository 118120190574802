import React, { lazy, Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
} from "react-router-dom";

import './App.scss';

import Main from '../Main/Main';

const Projects = lazy(() => import('../Projects/Projects'));
const About = lazy(() => import('../About/About'));
const Contacts = lazy(() => import('../Contact/Contacts'));
const Navigation = lazy(() => import('../../Navigation/Navigation'));

const renderLoader = () => (<div className='menu-button text-3xl'>Menu</div>)
const dummyFallback = () => (<div />)

const App = () => {
  return (
      <Router>
        <div className="App min-h-screen">
          <Suspense fallback={renderLoader()}>
            <Navigation />
          </Suspense>
          <Switch>
            <Suspense fallback={dummyFallback()}>
              <Route exact path={'/'} component={Main} />
              <Route exact path={'/me'} component={About} />
              <Route exact path={'/projects'} component={Projects} />
              <Route exact path={'/contacts'} component={Contacts} />
            </Suspense>

          </Switch>
        </div>
      </Router>
  );
};

export default App;
