import React, {Component, createRef} from 'react';

import './ProgressiveImageLoader.scss';

interface ProgressiveImageLoaderProps {
  src: string,
  thumb: string,
  alt: string,
}

interface ProgressiveImageLoaderState {
  ready: boolean,
}

class ProgressiveImageLoader extends Component<ProgressiveImageLoaderProps, ProgressiveImageLoaderState> {
  private element = createRef<HTMLDivElement>();
  constructor(props: ProgressiveImageLoaderProps) {
    super(props);
    this.state = {
      ready: false,
    }
  }

  loadItUp () {
    const buffer = new Image();
    buffer.onload = () => this.setState({ ready: true });
    buffer.src = this.props.src;
  }

  componentDidMount(): void {

    const intersectionObserver = new IntersectionObserver((entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.loadItUp();
        }
      })
    }));

    if (this.element.current) {
      const element: Element = this.element.current;
      intersectionObserver.observe(element);
    }


  }

  render() {
    const { ready } = this.state;
    const { thumb, alt, src } = this.props;
    return <div className='progressive-loading-wrapper' ref={this.element}>
      {ready ? <img alt={alt} className='original' src={src} /> : null}
      <img src={thumb} className={`thumb ${ready ? 'hide' : ''}`} alt={alt} />
    </div>
  }
}
export default ProgressiveImageLoader;
